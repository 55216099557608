import * as React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { GoTag } from "react-icons/go"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Scrollspy from "../components/scrollspy"

import "../styles/post.css"
import * as styles from "../styles/post.module.css"

const PostMath = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <Scrollspy />
      <article className={styles.Post}>
        <header className="Header">
          <h1>{post.frontmatter.title}</h1>
          <div className="PostDetails">
            {post.frontmatter.author &&
              <div>by {post.frontmatter.author}</div>
            }
            <span className="date" children={post.frontmatter.date}></span>
          </div>
        </header>
        <div
          className="PostContent"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        {post.frontmatter.tags && (
        <section className="Section">
          <div className="Tags">
            <GoTag className="icon" />
            {post.frontmatter.tags.sort().map(tag => (
              <span className="Tag" href={"/tags/"+tag} key={tag}>{tag}</span>
            ))}
          </div>
        </section>)}
      </article>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.8.0/styles/default.min.css"/>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.8.0/highlight.min.js" />
      <script>hljs.highlightAll();</script>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const { pageTitle, summary } = data.markdownRemark.frontmatter
  return <Seo title={pageTitle} description={summary} />
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        pageTitle
        title
        author
        date(formatString: "MMMM DD, YYYY")
        summary
        tags
      }
    }
  }
`

export default PostMath
