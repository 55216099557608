import * as React from "react"
import { GoChevronUp } from "react-icons/go"

import "../styles/post.css"

const Scrollspy = () => {
  const [offset, setOffset] = React.useState(0)

  React.useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset)
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const classNames = ['Scrollspy']

  if (offset > 110) {
    classNames.push('active')
  }

  const onClick = () => { window.scrollTo(0,0); }

  return (
    <div className={classNames.join(' ')} onClick={onClick}>
      <GoChevronUp size={54}/>
      <div style={{marginTop:'-26px', textAlign: 'center'}}>top</div>
    </div>
  )
}

export default Scrollspy